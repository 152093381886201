<template>
  <div class="content">
    <BaseH1 :text="'Zgłoszenia'" :short="true" class="h1 content__h1" />
    <ListDefaultItems
      v-if="currentResponses_TEMPORARY_FIX.value.length > 0"
      :list-items="currentResponses_TEMPORARY_FIX.value"
      :list-items-class="currentResponses_TEMPORARY_FIX.class"
      :list-items-type="currentResponses_TEMPORARY_FIX.type"
      @item-click="handleApplicationClick"
    />
    <SvgIllustrationsEmptySpace
      v-if="currentResponses_TEMPORARY_FIX.value.length === 0"
    />

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useAccountResponses from "@/composables/useAccountResponses";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ListDefaultItems from "@/components/List/ListDefaultItems.vue";
import SvgIllustrationsEmptySpace from "@/components/Svg/Illustrations/SvgIllustrationsEmptySpace.vue";
import { computed } from "vue";
import NavMobile from "@/components/NavMobile.vue";
import useIsMobile from "@/composables/useIsMobile";
import offerNannyResponsesService from "@/services/offerNannyResponses";
import { useUserStore } from "@/stores/user";

export default {
  components: {
    ListDefaultItems,
    BaseH1,
    SvgIllustrationsEmptySpace,
    NavMobile,
  },

  setup() {
    const { currentResponses } = useAccountResponses();
    const { isMobile } = useIsMobile();

    /**
     * TEMPORARY_FIX: while we don't have both incoming and outgoing responses
     */
    const currentResponses_TEMPORARY_FIX = computed(() => {
      const resultValue = [];

      if (currentResponses.value.type === "offer") {
        currentResponses.value.value.forEach((responseContainer) => {
          responseContainer.responses.forEach((response) => {
            if (response.on_offer_nanny_id_populated) {
              resultValue.push(response.on_offer_nanny_id_populated);
            }
          });
        });
      }

      if (currentResponses.value.type === "application") {
        currentResponses.value.value.forEach((responseContainer) => {
          responseContainer.responses.forEach((response) => {
            if (response.from_application_nanny_id_populated) {
              resultValue.push({
                ...response.from_application_nanny_id_populated,
                _linkedToResponseId: response._id,
              });
            }
          });
        });
      }

      return {
        value: resultValue,
        class: currentResponses.value.class,
        type: currentResponses.value.type,
      };
    });

    const userStore = useUserStore();

    const handleApplicationClick = async ({ _linkedToResponseId }) => {
      try {
        // TODO: check if user is Premium!
        await markOfferResponseAsSeen(_linkedToResponseId);
        await userStore.refetchUserStore();
      } catch (error) {
        alert(error.message);
      }
    };

    const markOfferResponseAsSeen = async (responseId) => {
      return await offerNannyResponsesService.updateOne(responseId, {
        isSeen: true,
      });
    };

    return {
      currentResponses_TEMPORARY_FIX,
      isMobile,
      handleApplicationClick,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  padding: 60px 0;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
  }
}
</style>
